export const environment = {
  name: 'acc',
  production: true,
  sharedSsoApi: 'https://shared-sso-api-acc.cubigo.com/api/v1/',
  azureApiManagementUrl: 'https://cubigo-acc.azure-api.net/web-ds/v1/',
  ssoUrl: 'https://acc-sso.cubigo.com/',
  appInsights: {
    instrumentationKey: '24b45cc1-5378-4553-af5d-5f2dde4248a4',
    instrumentationKeyEu: '24b45cc1-5378-4553-af5d-5f2dde4248a4',
    instrumentationKeyCa: '24b45cc1-5378-4553-af5d-5f2dde4248a4'
  },
  identityServer: {
    clientId: 'WebDigitalSignageAcc',
    clientSecret: '7b8d3793-f2dc-4060-b46f-5d1cbbad19b8',
    scope: 'digital_signage offline_access'
  },
  pubnub: {
    subKey: 'sub-c-d092abd2-74c2-11ea-9249-c28fb50b4a77',
    pubKey: 'pub-c-62385732-cd16-42c3-8b1c-655f34566b9f'
  },
  ocpApimSubscriptionKey: 'c1d040572d1f46239dd50413c42c73e1',
  ocpApimSubscriptionKeyEu: '',
  ocpApimSubscriptionKeyCa: '',
  refreshDuration: {
    mainContent: 60000 * 10,
    secondaryContent: 60000 * 35,
    config: 60000 * 60,
    prioNews: 60000 * 10,
    randomDelay: 60000 * 5
  }
};
